import { NumberFormatHelper } from "src/pages/Shared/NumberFormatHelper/NumberFormatHelper";
import classes from "./form-assets.module.scss";
import { useMemo } from "react";
import { IBPPFormPersonalPropertySegmentsInterface } from "src/interfaces/forms/BPPForm/bpp-form-personal-property-segments.interface";
import { localeEnUS } from "src/constants/locale-constants";
import { settingsService } from "src/services/settings.service";
import { NumberFormatOptions } from "@progress/kendo-react-intl";

const calculateTotals = (
  assets: IBPPFormPersonalPropertySegmentsInterface[]
) => {
  return assets.reduce(
    (totals, asset) => {
      if (!asset.isNew) {
        totals.lastYearTotals += asset.originalValue ?? 0;
      }

      if (!asset.isNew && !asset.removed) {
        totals.totalChanged +=
          (asset.MarketValue ?? 0) - (asset.originalValue ?? 0);
      }

      if (!asset.isNew && asset.removed) {
        totals.totalRemoved += asset.originalValue ?? 0;
      }

      if (asset.isNew && !asset.removed) {
        totals.totalAdded += asset.MarketValue ?? 0;
      }

      if (!asset.removed) {
        totals.currentYearTotals += asset.MarketValue ?? 0;
      }

      return totals;
    },
    {
      lastYearTotals: 0,
      totalAdded: 0,
      totalChanged: 0,
      totalRemoved: 0,
      currentYearTotals: 0,
    }
  );
};

export const BPPFormAssetsTableTotals = (props: {
  assets: IBPPFormPersonalPropertySegmentsInterface[];
  className?: string;
}) => {
  const settings = settingsService().settings;
  const currencyFormatOptions: NumberFormatOptions = {
    maximumFractionDigits: 0,
  };
  const {
    currentYearTotals,
    lastYearTotals,
    totalAdded,
    totalChanged,
    totalRemoved,
  } = useMemo(() => calculateTotals(props.assets.filter(a => !a.HasActiveSubSegments)), [props.assets]);

  return (
    <div className={props.className}>
      {/* <div>
        <table className="blue">
          <thead>
            <tr>
              <th className={classes.col}>Last Year Totals</th>
              <th className={classes.col}>
                <div className={classes.value}>
                  <p>$</p>
                  {NumberFormatHelper(
                    lastYearTotals,
                    localeEnUS,
                    currencyFormatOptions
                  )}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className={classes.col}>Total Removed</div>
              </td>
              <td className={classes.col}>
                <div className={`${classes.value} ${classes.removed}`}>
                  <p>$</p>
                  {totalRemoved > 0 && "-"}
                  {NumberFormatHelper(
                    totalRemoved,
                    localeEnUS,
                    currencyFormatOptions
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={classes.col}>Total Changed</div>
              </td>
              <td className={classes.col}>
                <div className={`${classes.value} ${classes.changed}`}>
                  <p>$</p>
                  {NumberFormatHelper(
                    totalChanged,
                    localeEnUS,
                    currencyFormatOptions
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={classes.col}>Total Added</div>
              </td>
              <td className={classes.col}>
                <div className={`${classes.value} ${classes.added}`}>
                  <p>$</p>
                  {NumberFormatHelper(
                    totalAdded,
                    localeEnUS,
                    currencyFormatOptions
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="blue">
          <thead>
            <tr>
              <th className={classes.col}>{settings.onlineFormsYear} Rendered Value</th>
              <th className={classes.col}>
                <div className={classes.value}>
                  <p>$</p>
                  {NumberFormatHelper(
                    currentYearTotals,
                    localeEnUS,
                    currencyFormatOptions
                  )}
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div> */}
    </div>
  );
};
